<template>
  <div class="contentTitle">
    <h3>Tickets</h3>
    <div class="right">
        <figure :class="{ animate: refresh }" @click="handleRefresh">
            <img src="/assets/images/refresh.svg" alt="" />
        </figure>
        <button
            class="btn"
            @click.prevent="create = true"
        >
            Create Ticket
        </button>
    </div>
  </div>
  <div class="contentMenu">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="javascript:void(0)"
          >All Tickets({{ total }})</a
        >
      </li>
    </ul>
    <div class="search-filters">
      <div class="nav-item" v-if="checked.length">
        <selected-options
          :items="[{ name: 'delete selected' }, { name: 'assign to' }]"
          @selectedItem="handleAction"
        />
      </div>
      <div class="nav-item">
        <filters :items="toFilter" @selectedItem="filterByItem" />
      </div>
      <div class="nav-item">
        <div class="siteSearch">
          <div class="searchPan">
            <i class="fa fa-search searchIcon" aria-hidden="true"></i>
            <input
              type="text"
              class="form-control"
              v-model="search_key"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="inbox-list">
    <div class="table-head">
      <div class="main-items">
        <span>
          <input
            ref="mainCheck"
            aria-label="select all devices"
            type="checkbox"
            @change="checkedAll"
          />
        </span>
        <span class="ticket">Ticket</span>
        <span>Product</span>
        <span>Assigned To</span>
        <span class="prio">Priority</span>
        <span>Status</span>
      </div>
    </div>

    <template v-if="loaded && filteredEntries.length">
      <template v-for="(entry, index) in filteredEntries" :key="index">
        <inbox-ticket
          :ticket="entry"
          :ticketToLoad="loadTicket"
          :canEdit="canCreate.includes(getUserProfile.role.id)"
          :allChecked="isChecked"
          :status="status"
          :users="users"
          :search-key="setKey"
          @check="handleTicketCheck"
          @updateTicket="getData(currentPage, `update${entry.ticket_id}`)"
        />
      </template>
    </template>
    <template v-else-if="!filteredEntries.length && loaded">
      <span class="empty" v-if="!filtered"> Inbox is currently empty. </span>
      <span class="empty" v-else> There are no results for your filters. </span>
    </template>
    <template v-else>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </template>
  </section>
  <div v-if="entries.length" class="pagination" ref="pagination">
    <span
      >Showing
      <b
        >{{ currentPage === 1 ? 1 : currentPage - 1 + "1" }}-{{
          currentTotal
        }}</b
      >
      from <b>{{ total }}</b></span
    >
    <img
      src="/assets/images/left-arrow.svg"
      alt="next_page"
      @click="currentPage > 1 ? (currentPage -= 1) : ''"
    />
    <img
      src="/assets/images/right-arrow.svg"
      alt="previous_page"
      @click="currentPage < pages ? (currentPage += 1) : ''"
    />
  </div>
    <CreateTicket
        v-if="create"
        :title="'Create Ticket'"
        @create="handleCreate"
        @close="create = false"
    />
    <AssignBox v-if="assignUserAction" :tickets="checked" @close="handleCloseAssign"/>
</template>

<script>
import { getCurrentInstance } from "vue";
import axiosInstance from "@/services/AxiosTokenInstance";
import { mapGetters } from "vuex";
import CreateTicket from "@/components/Create/Tickets/Index.vue";
import Filters from "@/components/Ui/General/FilterBy.vue";
import SelectedOptions from "@/components/Ui/CRUD/SelectedOptions.vue";
import InboxTicket from "./InboxTicket.vue";
import AssignBox from '@/components/Ui/Ticket/AssignBox.vue'
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data: function () {
    return {
      timeout: null,
      title: "Tickets List",
      entries: [],
      fullEntries: [],
      products: [],
      orgs: [],
      priority: [],
      filteredEntries: [],
      currentPage: 1,
      pages: 1,
      total: 0,
      perPage: 5,
      vw: true,
      edt: true,
      del: true,
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
      search_key: "",
      loadTicket: 0,
      isChecked: false,
      newTickets: 0,
      loaded: false,
      filtered: false,
      checked: [],
      filter: [],
      canCreate: [4, 5, 6, 7, 8],
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
        user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
        org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
      status: [
        {
          id: 0,
          name: "Choose a Status",
        },
      ],
      users: [],
      refresh: false,
      assignUserAction: false,
      create: false,
      setKey: ''
    };
  },
  components: {
    Filters,
    InboxTicket,
    SelectedOptions,
    AssignBox,
    CreateTicket
  },

  mixins: [api],

  watch: {
    entries(val) {
      this.filteredEntries = val;
    },
    currentPage(val) {
      this.getData(val);
    },
    isChecked(val) {
      if (val) this.checked.push(...this.filteredEntries);
      else this.checked = [];
    },

    filter() {
      this.getData(1);
    },

    search_key() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.getData(1);
      }, 1000);
    },
  },
  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },

    toFilter() {
        const toReturn = [
        {
          name: "priority",
          options: [
            {
              id: 1,
              icon: "/assets/images/low_flag.svg",
              name: "low",
            },
            {
              id: 2,
              icon: "/assets/images/medium_flag.svg",
              name: "medium",
            },
            {
              id: 3,
              icon: "/assets/images/high_flag.svg",
              name: "high",
            },
          ],
        },
        {
          name: "assigned to me",
          options: [
            {
              id: 1,
              name: "true",
            },
          ],
        },
        {
          name: "status",
          options: this.ticketStatus(),
        },
        {
          name: "products",
          options: this.productstoFilter(),
        },
        {
          name: "sites",
          options: this.sites(),
        },
      ];

      if(this.canCreate.includes(this.getUserProfile.role.id)) toReturn.push({
            name: "clients",
            options: this.api("clients"),
      })
      return toReturn
    },
  },
  methods: {
    async getData(value, isUpdate) {
      if (isUpdate) this.loadTicket = Number(isUpdate.replace("update", ""));
      this.loaded = false;
      this.fullEntries = [];
      this.products = [];
      this.orgs = [];
      this.priority = [];
      this.currentPage = value;
      let orgDataString = localStorage.getItem("organization");
      const client_id = this.client ? this.client.id : "";

      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;

        if (this.getUserProfile.role.id === 10) {
          if (!this.filter.length)
            response = await axiosInstance
              .get(
                `new-ticket-list?org_id=${orgData.id}&ownTickets=yes&searchKey=${this.search_key}&clientId=${client_id}&page=${this.currentPage}`
              )
              .finally(() => (this.loaded = true));
          else
            response = await axiosInstance
              .get(
                `new-ticket-list?org_id=${orgData.id}&ownTickets=yes&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&clientId=${client_id}&page=${this.currentPage}`
              )
              .finally(() => (this.loaded = true));
        } else {
          if (!this.filter.length)
            response = await axiosInstance
              .get(
                `new-ticket-list?org_id=${orgData.id}&searchKey=${this.search_key}&clientId=${client_id}&page=${this.currentPage}`
              )
              .finally(() => (this.loaded = true));
          else
            response = await axiosInstance
              .get(
                `new-ticket-list?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&clientId=${client_id}&page=${this.currentPage}`
              )
              .finally(() => (this.loaded = true));
        }

        this.setKey = this.search_key

        let responseData = response.data.data;
        if (responseData) {
          this.loadTicket = 0;
          this.newTickets = responseData.total;
          this.entries = responseData.data;
          this.perPage = responseData.per_page;
          this.total = responseData.total;
          this.pages = responseData.last_page;
        }
      }
    },

    async getStatus() {
      const response = await axiosInstance.get("ticket-status-list");
      this.status.push(...response.data.data);
    },
    ticketDetails(ticketId) {
      this.$router.replace(
        `/remote-support/tickets/ticket-messages/${ticketId}`
      );
    },

    handleCloseAssign() {
        this.assignUserAction = false
        this.getData()
    },

    handleAction(data) {
      if (data[0].name === "delete selected") {
        this.checked.forEach(async (element) => {
          await axiosInstance.delete(`ticket/${element.id}`).finally(() => {
            this.getData(this.currentPage);
          });
        });
      }
      if (data[0].name === "assign to") {
          this.assignUserAction = true
      }
    },

    async productstoFilter() {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      const allProds = [];
      let lastPage = 1;
      const client_id = this.client ? this.client.id : "";
      const response = await axiosInstance.get(
        `product?org_id=${orgData.id}&client_id=${client_id}&limit=100`
      );
      lastPage = response.data.data.last_page;
      allProds.push(...response.data.data.data);

      if (lastPage >= 2) {
        for (let i = 2; i <= lastPage; i++) {
          const res = await axiosInstance.get(
            `product?org_id=${orgData.id}&client_id=${client_id}&limit=100&page=${i}`
          );
          allProds.push(...res.data.data.data);
        }
      }

      return (
        allProds.map((prod) => {
          return {
            id: prod.id,
            name: prod.name,
            image: prod.product_images.length
              ? this.imgURL + prod.product_images[0].image
              : null,
          };
        }) || []
      );
    },

    filterByItem(data) {
      if (data[0] !== "remove") this.filtered = true;
      else this.filtered = false;
      switch (data[0]) {
        case "priority":
          this.filter = ["priorityId", data[1]];
          break;
        case "products":
          this.filter = ["productId", data[1]];
          break;
        case "status":
          this.filter = ["statusId", data[1]];
          break;
        case "sites":
          this.filter = ["projectId", data[1]];
          break;
        case "assigned to me":
          this.filter = ["myTickets", "yes"];
          break;
        case "clients":
          this.filter = ["clientId", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    async usersToSend() {
      this.users = await this.api("users");
    },

    async sites() {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      const allSites = [];
      let lastPage = 1;

      const client_id = this.client ? this.client.id : "";
      const response = await axiosInstance.get(
        `new-site-list?org_id=${orgData.id}&clientId=${client_id}&limit=100`
      );
      lastPage = response.data.data.last_page;
      allSites.push(...response.data.data.data);

      if (lastPage >= 2) {
        for (let i = 2; i <= lastPage; i++) {
          const res = await axiosInstance.get(
            `new-site-list?org_id=${orgData.id}&clientId=${client_id}&limit=100&page=${i}`
          );
          allSites.push(...res.data.data.data);
        }
      }

      return (
        allSites.map((site) => {
          return { id: site.id, name: site.name, image: null };
        }) || []
      );
    },

    async ticketStatus() {
      const allStatus = [];
      const response = await axiosInstance.get(`ticket-status-list`);
      allStatus.push(...response.data.data);
      return allStatus;
    },

    checkedAll(e) {
      this.isChecked = e.target.checked;
    },

    async handleCreate(data) {
        const toSend = {
            id_organization: this.org.id,
            title: data.title,
            description: data.description,
            id_project: data.site?.id || null,
            id_product: data.product?.id || null,
            id_user: this.user.id,
            ticket_questions: data.answers ? data.answers.filter(el => el.id).map(el => {
            return {
                q_id: el.q_id,
                a_id: el.id
            }
            }) : []
        };

        if (this.canCreate.includes(this.getUserProfile.role.id)) toSend.create_for = 'client'

        const response = await axiosInstance.post(`add-ticket`, toSend);

        if (response.status === 200) {

            this.create = false

            this.emitter.emit("create-room", {
                type: "ticket",
                ticketId: response.data.data,
                organizationId: this.org.id,
            });

            this.emitter.emit("alert", response.data.message);

            this.handleRefresh()
        }
    },

    handleTicketCheck(data) {
      if (!data[1]) {
        this.$refs.mainCheck.checked = false;
        const index = this.checked
          .map((element) => element.id)
          .indexOf(data[0]);
        this.checked.splice(index, 1);
      } else {
        const toAdd = this.filteredEntries.filter((el) => el.id === data[0])[0];
        this.checked.push(toAdd);
      }
    },

    handleRefresh() {
      this.refresh = true;
      this.getData(this.currentPage);
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
  },
  created() {
    this.currentPage = 1;
    this.getData(this.currentPage);
    this.getStatus();
    this.usersToSend();
  },
};
</script>

<style lang="scss" scoped>
.contentTitle {
    margin-bottom: 20px;
    .right {
        display: flex;
        align-items: center;
        gap: 1rem;

        figure {
            width: 25px;
            height: 25px;
            cursor: pointer;
            &.animate {
            animation: spin 0.5s;
            }
            img {
            width: 100%;
            height: 100%;
            }
        }
    }
  
}

.contentMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-filters {
    display: flex;
    gap: 20px;
    align-items: center;
    .searchPan{
        position: relative;
        display: flex;
        align-items: center;
        .searchIcon{
            position: absolute;
            left: 10px;
            color: #CAD6E2;
        }
        .form-control{
            border: 1px solid #ced4da;
            padding: 10px 15px;
            border-radius: 5px;
            &::placeholder{
                color: #CAD6E2;
            }
        }
      }
}

.ticketSearch {
  position: absolute;
  top: 30px;
}
.ticketSearch .searchPan {
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  height: 35px;
  color: #ced4da;
}
.ticketSearch .searchPan .searchIcon {
  position: absolute;
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticketSearch .searchPan .form-control {
  padding: 0 30px;
  border: 1px solid transparent;
  transition: 0.2s;
}
.ticketSearch .searchPan .form-control:focus {
  outline: none;
  border: 1px solid #ced4da;
  box-shadow: unset;
}
.ticketSearch .searchPan .form-control::placeholder {
  color: #ced4da;
}
.inbox-list {
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}
.inbox-list .table-head {
  position: sticky;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  box-shadow: 0px 1px 6px #cad6e280;
  border-bottom: solid 1px #f2f5f8;
  background: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 2;
}
.inbox-list .table-head .main-items {
  width: 98%;
  display: flex;
}
.inbox-list .table-head .main-items span {
  width: 19%;
  display: flex;
  font-size: 0.8rem;
}
.inbox-list .table-head .main-items span.ticket {
  width: 22%;
}
.inbox-list .table-head .main-items span:first-of-type {
  width: 5%;
  justify-content: flex-start;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 5px;
  margin-bottom: 10px;
}

.pagination img {
  width: 8px;
  cursor: pointer;
}

.pagination .underline {
  text-decoration: underline;
}

.empty {
  height: 500px;
  max-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-weight: 500;
}

.loading {
  height: 500px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@media only screen and (max-width: 750px) {
  .main-items {
    span {
      display: flex !important;
      width: 100% !important;
      &:not(span:nth-of-type(2)) {
        display: none !important;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
