<template>
  <div class="ticket" :class="{ notAssigned: !ticket.assigned_users.length }">
    <div class="info">
      <div class="item">
        <input
          :key="ticket.id"
          aria-label="select device"
          type="checkbox"
          :checked="allChecked ? true : false"
          @change="handleCheck"
        />
      </div>
      <div class="item large" @click="goToTicket">
        <div class="tkt-info">
          <div v-if="ticket.ticket_raised.avatar" class="img">
            <img :src="imgURL + ticket.ticket_raised.avatar" alt="" />
          </div>
          <span v-else class="no-img">{{
            ticket.ticket_raised.name.charAt(0)
          }}</span>
          <div class="desc">
            <div class="name" ref="title">{{ticket.title}} #{{ ticket.id }}</div>
            <template v-if="ticket.site?.client">
              <div class="client" ref="client">
                <span class="ttl">Client</span>
                <span class="txt">
                  {{ ticket.site.client.client_details.name }}</span
                >
              </div>
            </template>
            <div class="user" ref="user">
              <span class="usr-name">{{ ticket.ticket_raised.name }}</span>
              <span class="time"> • created {{ timeSince }}</span>
            </div>
          </div>
        </div>
      </div>
      <template v-if="ticket.product" ref="product">
        <div class="item">{{ ticket.product.name }}</div>
      </template>
      <template v-else>
        <div class="item">unknown product</div>
      </template>
      <div class="item assign" v-click-outside="clickOutside">
        <div v-if="!ticket.assigned_users.length" class="unassigned">
          <div class="toAssign" v-if="canEdit && toAssign">
            <input
              type="text"
              class="assignInput"
              :class="{ opened: userSrch.length > 2 }"
              v-model="userSrch"
              placeholder="search user"
            />
            <div class="usrDisplay">
              <span
                class="user"
                v-for="(user, index) in searchResult"
                :key="index"
                @click="assignUser(user)"
              >
                <div class="usr-info">
                  <template v-if="user.user.avatar">
                    <img :src="imgURL + user.user.avatar" alt="" />
                  </template>
                  <template v-else>
                    <span class="no-img">{{ user.user.name.charAt(0) }}</span>
                  </template>
                  <span ref="user">{{ user.user.name }}</span>
                </div>
              </span>
            </div>
          </div>
          <span class="assigning" v-else @click="toAssign = true">
            <template v-if="!Object.keys(selectedAssignment).length">
              Unassigned
            </template>
            <template v-else>
              <div v-if="selectedAssignment.user.avatar" class="img">
                <img :src="imgURL + selectedAssignment.user.avatar" alt="" />
              </div>
              <span v-else class="no-img">{{
                selectedAssignment.user.name.charAt(0)
              }}</span>
              <div class="user">
                <span class="usr-name">{{ selectedAssignment.user.name }}</span>
                <span class="designation">Loading...</span>
              </div>
            </template>
          </span>
        </div>
        <div v-else class="assigned" ref="usr-avt">
          <template v-if="ticket.assigned_users.length === 1">
            <div v-if="ticket.assigned_users[0].avatar" class="img">
              <img :src="imgURL + ticket.assigned_users[0].avatar" alt="" />
            </div>
            <span v-else class="no-img">{{
              ticket.assigned_users[0].name.charAt(0)
            }}</span>
            <div class="user">
              <span class="usr-name">{{ ticket.assigned_users[0].name }}</span>
              <span class="designation">{{
                ticket.assigned_users[0].role_type
              }}</span>
            </div>
          </template>
          <template
            v-else
            v-for="(user, index) in ticket.assigned_users"
            :key="index"
          >
            <template v-if="index < 4">
              <div
                class="user-avatar"
                :class="{ more: index === 3 }"
                :title="user.name"
              >
                <template v-if="index < 3">
                  <img v-if="user.avatar" :src="imgURL + user.avatar" alt="" />
                  <span v-else>{{ user.name.charAt(0) }}</span>
                </template>
                <template v-if="index === 3">
                  <span class="more"
                    >+{{ ticket.assigned_users.length - 3 }}</span
                  >
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="item priority" v-click-outside="clickOutsidePrios">
        <div class="current" @click="openPriorities = true">
          <div class="prio">
            <template v-if="!Object.keys(selectedPriority).length">
              <img :src="priority.icon" alt="" />
              <span :class="priority.name">{{ priority.name }}</span>
            </template>
            <template v-else>
              <img :src="selectedPriority.icon" alt="" />
              <span :class="selectedPriority.name">{{
                selectedPriority.name
              }}</span>
            </template>
          </div>
          <div class="arrow" v-if="canEdit">
            <img src="/assets/images/left-arrow-grey.svg" />
          </div>
        </div>
        <transition name="fadeHeight" mode="out-in">
          <div
            v-if="canEdit && openPriorities"
            ref="prios"
            class="toChoose"
            :class="{ openPriority: !closePrios, closePriority: closePrios }"
          >
            <div
              class="priority-option"
              v-for="(prio, index) in priorities"
              :key="index"
              @click="handlePrio(prio)"
            >
              <img :src="prio.icon" />
              <span :class="prio.name">{{ prio.name }}</span>
              <img
                class="arrow"
                v-if="prio.id === undefined"
                src="/assets/images/left-arrow-grey.svg"
              />
            </div>
          </div>
        </transition>
      </div>
      <div class="item" v-click-outside="clickOutsideStatus">
        <div class="current-status" @click="openStatus = !openStatus">
          <span>{{ ticket.ticket_status.status_details?.name }}</span>
          <div class="arrow" v-if="canEdit">
            <img src="/assets/images/left-arrow-grey.svg" />
          </div>
        </div>
        <transition name="fadeHeight" mode="out-in">
          <div
            v-if="canEdit && openStatus"
            ref="status"
            class="chooseStatus"
            :class="{ openStatus: openStatus, closeStatus: !openStatus }"
          >
            <div
              class="status-option"
              v-for="(stat, index) in status"
              :key="index"
              @click="handleStatus(stat)"
            >
              <span :class="stat.name">{{ stat.name }}</span>
              <img
                class="arrow"
                v-if="!stat.id"
                src="/assets/images/left-arrow-grey.svg"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div
      class="options"
      v-if="canEdit"
      @click="edit = !edit"
      v-click-outside="clickOutsideOptions"
    >
      <img src="/assets/images/black_dots.svg" alt="" />
      <options-modal v-if="edit" :options="[]" @delete="del()" />
    </div>
    <delete-ticket
      v-if="deleteTicket.length"
      :ticket="deleteTicket[0]"
      @close="handleDelete"
    />
    <unassign
      v-if="unassignTicket.length"
      :ticket="unassignTicket[0]"
      @close="handleUnassign"
    />
    <assign
      v-if="assignTicket.length"
      :ticket="assignTicket[0]"
      :users="users"
      @close="handleAssign"
    />
  </div>
</template>

<script>
import DeleteTicket from "./Overview/Delete.vue";
import axiosInstance from "@/services/AxiosTokenInstance";
import optionsModal from "@/components/Ui/General/Options.vue";
import { mapActions } from "vuex";
import {
  SEARCH_CHAT_USERS_ACTION,
  UPDATE_TICKET_USERS_ASSIGNMENT_ACTION,
  CHANGE_TICKET_PRIORITY_ACTION,
} from "@/store/storeconstants";
import Assign from "./Overview/Assign.vue";
import Unassign from "./Overview/Unassign.vue";
export default {
  props: {
    ticket: {
      required: false,
      type: Object,
    },
    ticketToLoad: {
      required: false,
      type: Number,
    },
    allChecked: {
      required: true,
      type: Boolean,
    },
    canEdit: {
      required: true,
      type: Boolean,
    },
    status: {
      type: Array,
    },
    users: {
      type: Array,
    },
    searchKey: {
        type: String
    }
  },
  components: {
    optionsModal,
    DeleteTicket,
    Assign,
    Unassign,
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      toAssign: false,
      userSrch: "",
      searchResult: [],
      openPriorities: false,
      deleteTicket: [],
      organization_details: JSON.parse(localStorage.getItem("organization")),
      priorities: [
        {
          id: undefined,
          icon: "/assets/images/nan_flag.svg",
          name: "chose priority",
        },
        {
          id: 1,
          icon: "/assets/images/low_flag.svg",
          name: "low",
        },
        {
          id: 2,
          icon: "/assets/images/medium_flag.svg",
          name: "medium",
        },
        {
          id: 3,
          icon: "/assets/images/high_flag.svg",
          name: "high",
        },
      ],
      selectedPriority: {},
      selectedAssignment: {},
      closePrios: false,
      openStatus: false,
      assignTicket: [],
      toStat: 0,
      unassignTicket: [],
    };
  },

  watch: {

    searchKey(val) {
        if (val.length) {
            const regex = new RegExp(val, 'gi')

            const keys = Object.keys(this.$refs).filter(el => el !== 'usr-avt')
            keys.forEach(el => {
                this.$refs[el].innerHTML = this.$refs[el].innerHTML.replace(regex, match => `<mark>${match}</mark>`)
                
                console.log(this.$refs[el])
            })

        }
        
    },

    ticket() {
      this.selectedAssignment = {};
      this.selectedPriority = {};

      if (this.$refs["usr-avt"]) {
        const avatars = Array.from(this.$refs["usr-avt"].children).filter(
          (el) => el.classList.contains("user-avatar")
        );
        if (avatars.length) {
          let value = avatars.length;
          avatars.forEach((element) => {
            element.style.zIndex = value;
            value -= 1;
          });
        }
      }
    },
    userSrch(val) {
      if (val.length) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchData();
        }, 1000);
      } else {
        this.searchData();
      }
    },
  },
  computed: {
    timeSince() {
      const last_updated = Date.parse(this.ticket.updated_at);
      const seconds = Math.floor((new Date() - last_updated) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `at ${this.ticket.updated_at.split("T")[0]}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `at ${this.ticket.updated_at.split("T")[0]}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 5)
          return `at ${this.ticket.updated_at.split("T")[0]}`;
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "day ago" : "days ago"
        }`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "hour ago" : "hours ago"
        }`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "minute ago" : "minutes ago"
        }`;
      }
      return `${Math.floor(seconds)} ${
        Math.floor(seconds) === 1 ? "second ago" : "seconds ago"
      }`;
    },
    priority() {
      let icon;
      let name;
      let id = this.ticket.ticket_priority.priority_details.id;

      switch (this.ticket.ticket_priority.priority_details.id) {
        case 1:
          icon = "/assets/images/low_flag.svg";
          name = "low";
          break;
        case 2:
          icon = "/assets/images/medium_flag.svg";
          name = "medium";
          break;
        case 3:
          icon = "/assets/images/high_flag.svg";
          name = "high";
          break;

        default:
          icon = "/assets/images/nan_flag.svg";
          name = "set priority";
          break;
      }

      return { id, icon, name };
    },
  },
  methods: {
    ...mapActions("chat", {
      fetchChatUsers: SEARCH_CHAT_USERS_ACTION,
    }),

    ...mapActions("ticket", {
      updateUserAssignment: UPDATE_TICKET_USERS_ASSIGNMENT_ACTION,
      changeTicketPriority: CHANGE_TICKET_PRIORITY_ACTION,
    }),

    goToTicket() {
      this.$router.push({ path: `/remote-support/tickets/${this.ticket.id}` });
    },

    del() {
      this.edit = false;
      this.deleteTicket.push(this.ticket);
    },

    async handleDelete(value) {
      if (value) {
        await axiosInstance.delete(`ticket/${this.ticket.id}`).then((data) => {
          this.$emit("updateTicket");
          this.emitter.emit("alert", data.data.message);
        });
      }
      this.deleteTicket = [];
    },

    clickOutside() {
      this.toAssign = false;
    },

    clickOutsideOptions() {
      this.edit = false;
    },

    clickOutsidePrios() {
      this.openPriorities = false;
    },
    clickOutsideStatus() {
      this.openStatus = false;
    },

    async searchData() {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      if (this.userSrch.length > 0) {
        await axiosInstance
          .get(
            `get-users?org_id=${orgData.id}&searchKey=${this.userSrch}&type=users&limit=100`
          )
          .then((data) => {
            this.search_data_available = true;
            if (data) {
              if (Object.keys(data.data.data.data).length !== 0) {
                this.searchResult = data.data.data.data;
              } else {
                this.searchResult = [];
              }
            } else {
              this.searchResult = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.searchResult = [];
      }
    },

    async assignUser(user) {
      this.selectedAssignment = user;
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let params = {
          ticket_id: this.ticket.id,
          organization_id: orgData.id,
          users: [{ id_user: user.user.id }],
        };
        this.userSrch = "";
        this.toAssign = false;
        await this.updateUserAssignment(params).then((data) => {
          this.$emit("updateTicket");
          this.emitter.emit("alert", data);
        });
      }
    },

    handleCheck(e) {
      const value = e.target.checked;
      this.$emit("check", [this.ticket.id, value]);
    },

    async handlePrio(prio) {
      if (this.priority.id !== prio.id && prio.id !== undefined) {
        this.selectedPriority = prio;
        this.openPriorities = false;
        this.closePrios = false;
        await this.changeTicketPriority({
          ticket_id: this.ticket.id,
          status: prio.id,
        }).then((data) => {
          this.$emit("updateTicket");
          this.emitter.emit("alert", data);
        });
      } else {
        this.closePrios = true;
        setTimeout(() => {
          (this.openPriorities = false), (this.closePrios = false);
        }, 25);
      }
    },
    async handleStatus(stat) {
      if (!stat.id) {
        this.openStatus = false;
      } else {
        if (this.ticket.ticket_status.status === 1 && stat.id < 4) {
          this.assignTicket.push(this.ticket);
          this.toStat = stat.id;
        } else if (this.ticket.ticket_status.status !== 1 && stat.id === 1) {
          this.unassignTicket.push(this.ticket);
          this.toStat = stat.id;
        } else {
          this.toStat = stat.id;
          await axiosInstance
            .post("change-ticket-status", {
              ticket_id: this.ticket.id,
              status_id: this.toStat,
            })
            .finally(() => {
              this.$emit("updateTicket");
              this.emitter.emit("alert", "Ticket Updated");
              this.toStat = 0;
            });
        }
      }
    },

    async handleAssign(data) {
      if (data) {
        let orgData = JSON.parse(localStorage.getItem("organization"));
        let params = {
          ticket_id: this.ticket.id,
          organization_id: orgData.id,
          users: data.map((el) => {
            return { id_user: el.id };
          }),
        };

        await this.updateUserAssignment(params).then(async () => {
          await axiosInstance
            .post("change-ticket-status", {
              ticket_id: this.ticket.id,
              status_id: this.toStat,
            })
            .finally(() => {
              this.$emit("updateTicket");
              this.emitter.emit("alert", "Ticket Updated");
              this.toStat = 0;
              this.assignTicket = [];
            });
        });
      } else this.assignTicket = [];
    },

    async handleUnassign(data) {
      if (data) {
        let orgData = JSON.parse(localStorage.getItem("organization"));
        const toSend = {
          org_id: orgData.id,
          ticket_id: this.ticket.id,
          user_ids: this.ticket.assigned_users.map((el) => el.id),
        };
        await axiosInstance
          .post(`un-assign-ticket-from-all-users`, toSend)
          .then(() => {
            this.$emit("updateTicket");
            this.emitter.emit("alert", "Ticket Updated");
          });
      } else this.unassignTicket = [];
    },
  },
  mounted() {
    if (this.$refs["usr-avt"]) {
      const avatars = Array.from(this.$refs["usr-avt"].children).filter((el) =>
        el.classList.contains("user-avatar")
      );
      if (avatars.length) {
        let value = avatars.length;
        avatars.forEach((element) => {
          element.style.zIndex = value;
          value -= 1;
        });
      }
    }

    // this.$refs.ticket.textContent.replace("obi", match => `<mark>${match}</mark>`)
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.overlay {
  width: 100%;
  height: 80px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #f2f5f8;
}
.overlay .bar {
  width: 15%;
  height: 30px;
  background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
  background-size: 400% 400%;
  animation: load 1s ease infinite;
  border-radius: 30px;
}
.overlay .bar.large {
  width: 20%;
  background: none;
  animation: unset;
  border-radius: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.overlay .bar.large .round {
  width: 30px;
  height: 30px;
  background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
  background-size: 400% 400%;
  animation: load 0.5s ease infinite;
  border-radius: 50%;
}
.overlay .bar.large .txt {
  display: flex;
  flex-direction: column;
  width: 85%;
  gap: 2px;
}
.overlay .bar.large .txt .top {
  height: 17px;
}
.overlay .bar.large .txt .bottom {
  height: 11px;
}
.overlay .bar.large .txt div {
  background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
  background-size: 400% 400%;
  animation: load 0.5s ease infinite;
  border-radius: 30px;
}
.ticket {
  height: 80px;
  display: flex;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;
}
.ticket.notAssigned {
  background: $grey-opacity;
}
.ticket .info {
  width: 98%;
  display: flex;
}
.ticket .info .item {
  width: 19%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.ticket .info .item.large {
  width: 22%;
}
.ticket .info .item.client {
  font-size: 0.7rem;
  padding: 0 50px 0 0;
}
.ticket .info .item:first-of-type {
  width: 5%;
  justify-content: flex-start;
}
.ticket .options {
  width: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
}
.ticket .options .dropdown-options {
  top: 60%;
}

.ticket .info .item .tkt-info {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ticket .info .item .tkt-info .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.ticket .info .item .tkt-info .img img {
  height: 100%;
  object-fit: cover;
}
.ticket .info .item .tkt-info .no-img {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: var(--primary-color);
}
.ticket .info .item .tkt-info .desc {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
}
.ticket .info .item .tkt-info .desc .name:first-letter {
  text-transform: uppercase;
}
.ticket .info .item .tkt-info .desc .client {
  display: flex;
  gap: 0.2rem;
  font-size: 0.8rem;
}
.ticket .info .item .tkt-info .desc .client .ttl {
  font-weight: 400;
}
.ticket .info .item .tkt-info .desc .client .txt {
  font-weight: 500;
}
.ticket .info .item .tkt-info .desc .user {
  font-size: 0.7rem;
}
.ticket .info .item .tkt-info .desc .user .usr-name {
  font-weight: 500;
}
.ticket .info .item .tkt-info .desc .user .time {
  color: #cad6e2;
  font-weight: 400;
}

.ticket .info .item.assign .unassigned {
  color: #cad6e2;
  font-weight: 400;
}

.ticket .info .item.assign .unassigned .toAssign {
  position: relative;
}

.ticket .info .item.assign .unassigned .toAssign .assignInput {
  height: 2rem;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: black;
}

.ticket .info .item.assign .unassigned .toAssign .assignInput::placeholder {
  color: #cad6e2;
}

.ticket .info .item.assign .unassigned .toAssign .assignInput.opened {
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: solid 1px #cad6e280;
}

.ticket .info .item.assign .unassigned .toAssign .usrDisplay {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  max-height: 200px;
  overflow: auto;
  z-index: 10;
}

.ticket
  .info
  .item.assign
  .unassigned
  .toAssign
  .usrDisplay::-webkit-scrollbar {
  width: 10px;
}

.ticket
  .info
  .item.assign
  .unassigned
  .toAssign
  .usrDisplay::-webkit-scrollbar-track {
  background: transparent;
}

.ticket
  .info
  .item.assign
  .unassigned
  .toAssign
  .usrDisplay::-webkit-scrollbar-thumb {
  background: #f2f5f8;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  transition: 0.2s;
}

.ticket
  .info
  .item.assign
  .unassigned
  .toAssign
  .usrDisplay::-webkit-scrollbar-thumb:hover {
  background: #e6e8eb;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.ticket .info .item.assign .unassigned .toAssign .usrDisplay .user {
  color: black;
  font-size: 0.8rem;
  padding: 10px;
  border-bottom: solid 1px #cad6e280;
  transition: 0.2s;
}

.ticket .info .item.assign .unassigned .toAssign .usrDisplay .user:hover {
  background: #cad6e280;
}

.ticket .info .item.assign .unassigned .toAssign .usrDisplay .user .usr-info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ticket
  .info
  .item.assign
  .unassigned
  .toAssign
  .usrDisplay
  .user
  .usr-info
  img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.ticket
  .info
  .item.assign
  .unassigned
  .toAssign
  .usrDisplay
  .user
  .usr-info
  .no-img {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: var(--primary-color);
}

.ticket .info .item.assign .unassigned .assigning {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ticket .info .item.assign .unassigned .assigning .no-img {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: var(--primary-color);
}

.ticket .info .item.assign .unassigned .assigning .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.ticket .info .item.assign .unassigned .assigning .img img {
  height: 100%;
  object-fit: cover;
}

.ticket .info .item.assign .unassigned .assigning .user {
  display: flex;
  flex-direction: column;
}
.ticket .info .item.assign .unassigned .assigning .user .designation {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.6rem;
}

.ticket .info .item.assign .assigned {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ticket .info .item.assign .assigned .user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: white;
  overflow: hidden;
  border: solid 2px #f2f5f8;
  margin-left: -25px;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticket .info .item.assign .assigned .user-avatar.more {
  color: black;
  background: #f2f5f8;
  font-size: 0.7rem;
  margin-left: -20px;
}
.ticket .info .item.assign .assigned .user-avatar:first-of-type {
  margin-left: unset;
}
.ticket .info .item.assign .assigned .user-avatar img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.ticket .info .item.assign .assigned .no-img {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: var(--primary-color);
}

.ticket .info .item.assign .assigned .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.ticket .info .item.assign .assigned .img img {
  height: 100%;
  object-fit: cover;
}

.ticket .info .item.assign .assigned .user {
  display: flex;
  flex-direction: column;
}
.ticket .info .item.assign .assigned .user .designation {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.6rem;
}

.ticket .info .item.priority {
  text-transform: capitalize;
  font-weight: 400;
  position: relative;
  display: flex;
}
.ticket .info .item.priority .current {
  display: flex;
  gap: 5px;
}
.ticket .info .item.priority .current .prio {
  display: flex;
  gap: 5px;
}
.ticket .info .item.priority .current .arrow {
  width: 10px;
  transform: rotate(270deg);
}

.ticket .info .item.priority span {
  color: #cad6e2;
}
.ticket .info .item.priority .medium {
  color: #fb9a4b;
}
.ticket .info .item.priority .high {
  color: #ed0423;
}

.ticket .info .item.priority .toChoose {
  position: absolute;
  top: calc(50% - 1rem);
  box-shadow: 0px 1px 6px #cad6e280;
  border-radius: 5px;
  overflow: hidden;
  z-index: 3;
  background: white;
}
.ticket .info .item.priority .toChoose.openPriority {
  animation: open 0.2s linear forwards;
}
.ticket .info .item.priority .toChoose.closePriority {
  animation: close 0.2s linear forwards;
}
.ticket .info .item.priority .toChoose .priority-option {
  height: 2rem;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  border-bottom: solid 1px #cad6e280;
  user-select: none;
}
.ticket .info .item.priority .toChoose .priority-option .arrow {
  width: 10px;
  transform: rotate(90deg);
}
.ticket .info .item.priority .toChoose .priority-option:last-of-type {
  border: none;
}
.ticket .info .item.priority .toChoose .priority-option:hover {
  background: #cad6e280;
}

.item {
  position: relative;
  .current-status {
    display: flex;
    gap: 0.5rem;
    span {
      font-size: 0.8rem;
    }
    .arrow {
      width: 10px;
      height: 10px;
      img {
        width: 100%;
        height: 100%;
        transform: rotate(270deg);
      }
    }
  }
  .chooseStatus {
    position: absolute;
    top: calc(50% - 1rem);
    box-shadow: 0px 1px 6px #cad6e280;
    border-radius: 5px;
    overflow: hidden;
    z-index: 3;
    background: white;

    &.openStatus {
      animation: open 0.2s linear forwards;
    }

    &.closeStatus {
      animation: close 0.2s linear forwards;
    }

    .status-option {
      height: 2rem;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;
      border-bottom: solid 1px #cad6e280;
      user-select: none;
      font-weight: 400;
      transition: 0.2s;
      .arrow {
        width: 10px;
        height: 10px;
        transform: rotate(90deg);
      }
      &:hover {
        background: #cad6e280;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .info {
    .item {
      display: flex !important;
      width: 100% !important;
      &:not(.item:nth-of-type(2)) {
        display: none !important;
      }
    }
  }
}

@keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 250px;
  }
}
@keyframes close {
  from {
    max-height: 250px;
  }
  to {
    max-height: 0px;
  }
}
@keyframes load {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>